<template>
   <div>
      <!-- Breadcrumb start -->
       <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_2 font_bold mb_23">Ergo <span class="text_primary">essentials</span></h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Ergo Essentials</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <section :class="!company_code ? 'work_from_office how_else_ergo mb-0' : 'work_from_office how_else_ergo'">
         <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'posture_perfect' }">
                              <img src="../assets/images/ergo-essential/ergo-essential1.png" alt="img" class="mb_66">
                              
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Posture Perfect</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Feel great and enjoy being the most impressive one in the room.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>

                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'sitting_pretty' }">
                              <img src="../assets/images/ergo-essential/ergo-essential2.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                      <img src="../assets/images/home-essentials/sitting_pretty.png" alt="img" class="mb_66">
                    </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Sitting Pretty</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Learn how to sit in total comfort day in and day out.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'sit_stand_basics' }">
                              <img src="../assets/images/ergo-essential/ergo-essential3.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                      <img src="../assets/images/home-essentials/sit_stand.png" alt="img" class="mb_66">
                    </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Sit/Stand Basics</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Do you sit or stand when you work? How about a bit of both? Learn how.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'typing_dos_and_donts' }">
                              <img src="../assets/images/ergo-essential/ergo-essential4.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                      <img src="../assets/images/home-essentials/typing.png" alt="img" class="mb_66">
                    </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Typing Do’s and Don’ts
                                 </h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    These typing tips will protect your hands and speed you up.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'mousing_dos_and_donts' }">
                              <img src="../assets/images/ergo-essential/ergo-essential5.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                      <img src="../assets/images/home-essentials/mousing.png" alt="img" class="mb_66">
                    </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Mousing Do’s and Don’ts
                                 </h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Healthy mousing is key to your well being and productivity.

                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2 border-0">
                           <router-link :to="{ name: 'mindful_ergonomics' }">
                              <img src="../assets/images/ergo-essential/ergo-essential6.png" alt="img" class="mb_66">
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Mindful Ergonomics</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Being mindful of how you do what you do is key to staying healthy.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </section>
      <section class="take_an_ergonomic_parttwo mb_44" v-show="company_code">

         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-10">
                  <div class="ergonomic-image pt_26 pb_26">
                     <div class="overlay">
                        <div class="row">
                           <div class="col-md-12 col-lg-7 col-xl-6">
                              <div class="left-section pr_60 pl_60">
                                 <h6 class="sub_heading_1 font_bold mb_22 text-white">Take an ergonomic self-assessment
                                 </h6>
                                 <p class="text-white p_20 mb_54">Take a self-assessment today and see how ergo can help
                                    you feel great, enjoy more energy and supercharge your productivity. </p>
                                 <div class="mt_14">
                                    <router-link :to="{ name: 'self_assessment_signup' }"
                                       class="btn btn_secondary  font_size_16">Take a Self-Assessment
                                    </router-link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- video model
     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
                  <iframe src="https://player.vimeo.com/video/661824377?h=18efe0e91c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Sitting Pretty"></iframe>
             </div>
          </div>

        </div>
      </div>
    </div>
   <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
              <iframe src="https://player.vimeo.com/video/661823396?h=15f2cd13b0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Your Sit/Stand Desk"></iframe>
            </div>
          </div>

        </div>
      </div>
    </div>
   <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
              <iframe src="https://player.vimeo.com/video/661823686?h=da45e97233&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Typing (and texting) Do&amp;#039;s and Don&amp;#039;ts"></iframe>
            </div>
          </div>

        </div>
      </div>
    </div>
   <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-body">

            <div class="video_elements">
              <a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                  src="../assets/images/close_modal.png"></a>
              <iframe src="https://player.vimeo.com/video/661824040?h=15b3eb885c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Mousing Do&amp;#039;s and Don&amp;#039;ts"></iframe>
            </div>
          </div>

        </div>
      </div>
    </div> -->

   </div>
</template>

<script>
   export default {
      data () {
         return {
            company_code: true,
         }
      },
      name: "ergo_essentials",
       mounted(){
         document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
         let data = this.$store.getters["auth/authUser"];
         let company_data = data.client;
         
         if(company_data.self_assessment_button_status == 0){
            this.company_code = false;
         }
         
         if (
          localStorage.getItem("userToken") == null &&
          localStorage.getItem("employeeEmail") == null
         ) {
            this.$router.push({ name: "login" }).catch(() => true);
         }  
      }      
   }
</script>
